<template>
<main id="main" class="contact-used">
  <div class="wrap1160">
    <ContactItemInfo :parent="$data" :getItem="getItem" :favorite="favorite"/>
    <ContactForm :parent="$data" v-if="editFlag"/>
    <Completion v-else-if="completedFlag" :parent="$data" />
    <ComfirmForm :parent="$data" v-else/>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods'
import ComfirmForm from '../../components/Contact/Comfirm'
import Completion from '../../components/Contact/Completion'
import ContactForm from '../../components/Contact/Form'
import ContactItemInfo from '../../components/Contact/ItemInfo'
export default {
  components : {
    ContactForm,
    ComfirmForm,
    Completion,
    ContactItemInfo
  },
  data() {
    return {
      typeTag         : 'STOCK_PAGE_SEARCH_TYPE',
    }
  },
  methods : {
    getItem(params) {
      return Methods.apiExcute('public/get-stock-info', this.$i18n.locale, params)
    },
    favorite(params) {
      return Methods.apiExcute('private/favorite-stock', this.$i18n.locale, params)
    }
  }
}
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
